import React from 'react';
import { useTranslation } from "react-i18next";
import Content from '../containers/Content';
import Title from '../components/Title';
import ResponsiveImage from '../components/services/ResponsiveImage';
import imageUrls from '../utils/imagePaths';
import CommonButton from '../components/CommonButton';
import ContactPersonCard from '../components/services/ContactPersonCard';
import contacts from '../assets/contacts/contacts.json';

const SaleServiceContent = () => {
    
    const { t } = useTranslation();

    return (
        <Content className="px-8 mx-auto md:w-full">
            <div className="flex flex-col my-auto gap-6">
                <Title title={t("service_page.sale.header")} />
                <div className="flex flex-col lg:flex-row gap-6">
                    <div className="flex flex-col gap-2 text-base leading-relaxed font-medium lg:w-3/4 lg:border-b lg:border-px lg:border-white lg:pb-6">
                        <div>{t("service_page.sale.subtext1")}</div>
                        <div>{t("service_page.sale.subtext2")}</div>
                        <div>{t("service_page.sale.subtext3")}</div>
                    </div>
                    <div className="flex flex-col lg:w-1/4 lg:gap-2">
                        <CommonButton to="/contact?subject=sale" special className="w-full self-end max-w-[50%] lg:max-w-[75%] px-6 py-3 border-white hover:shadow-subtle-glow transition-transform transform hover:scale-105 lg:mt-1">
                            {t("service_page.sale.call_to_action")}
                        </CommonButton>
                        <div className="hidden lg:block w-full self-end max-w-[50%] lg:max-w-[75%] lg:my-auto">
                            <ContactPersonCard className="" contact={contacts[1]} />
                        </div>
                    </div>
                    <div className="lg:hidden flex border-b border-px border-white pb-2"></div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-24 pt-2 mx-auto">
                    <ResponsiveImage imagePath={imageUrls.siemens} />
                    <ResponsiveImage imagePath={imageUrls.smc} />
                </div>
            </div>
        </Content>
    );
};

export default SaleServiceContent;
